.form-check-input:checked {
  background-color: #469b88 !important;
  border-color: #469b88 !important;
}

.light-gray-border {
  border: 1px solid #e1e1e1 !important;
}


