.setting-tab li .nav-link {
  background-color: unset !important;
  font-size: 20px;
  padding: 10px 70px !important;
  font-weight: 500;
}

.setting-wrp .nav {
  background-color: unset !important;
  border: none !important;
}

.setting-wrp .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: unset !important;
  color: #000 !important;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 2px solid #000;
  border-radius: 0px;
}

.col-xl-6.setting-wrp {
  margin-top: 3rem;
}

.your-details-section {
  display: flex;
  width: 906px;
  height: 152px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex-shrink: 0;

}

.main-section-left {
  /* background-color: #fff;
  padding: 10px 20px;
  border-radius: 10px; */
  width: 906px;
  height: 114px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #FFF;
  padding: 14px 20px;
}

.main-2fa-left {
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 20px;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
}

.heading h3 {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 20px;
}

.header-delete-acc-h3 {
  font-size: 20px !important;
  line-height: 30px !important;
  font-weight: 500;
  margin-bottom: 10px !important;
  margin-top: 10px;
  display: inline-flex;
}

.heading-2fa h3 {
  font-size: 30px;
  line-height: 30px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: #240f51;
  font-family: Neurialgrotesk, sans-serif;
}

.setting_2fa_text_color {
  color: rgba(144, 144, 144, 1);
  font-size: 14px;
  line-height: 19.5px;
  font-weight: 400;
  margin-bottom: 0px;
  letter-spacing: 0.86px;
  text-align: center;
  padding: 0px 30px 0px 30px;
}

.verify-btn {
  padding: 16px 90px !important;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
}

.form-control-lg-email {
  min-height: calc(1.5em + (1rem + 2px));
  padding: 0.5rem 1rem;
  font-size: 16px;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 5px 30px 0px #0000001a;
}

.email-box-padding {
  padding: 0px 20px 0px 20px;
}

.all-set-box-padding {
  padding: 0px 5px 0px 5px;
}

.field-form {
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.edit-settings-input {
  width: 231px;
  height: 45px;
  border-radius: 8px;
  background: #EFEDED;
  padding: 10px;
  font-family: 'Neurialgrotesk';
  font-weight: 400;
}

.field-form label {
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
}

input.input-form {
  height: 54px;
  padding: 14px 20px;
  margin-top: 10px;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: none;
}

.profile-img-setting img {
  width: 25%;
}

.header-section {
  display: flex;
  flex-Direction: row;
  align-Items: center;
  justify-Content: space-between
}

.profile-img-setting {
  display: flex;
  position: relative;
  justify-content: flex-start;
}

.profile-wrp {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */

}

.camera img {
  width: 18px;
  height: 18px;
}

.user-settings {
  height: 45px;
  padding: 14px 20px;
  margin-top: 10px;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: none;
}

.camera {
  position: absolute;
  left: 64%;
  background-color: #469b88;
  padding: 9px;
  top: -9%;
  text-align: center;
  border-radius: 50%;
  display: flex;
  border: 4px solid #f5f5f5;
  cursor: pointer;
}

.half-width {
  width: 50% !important;
}

.setting-padding-security {
  padding: 2px 11px 11px 0px !important;
}

.profile-img-margin {
  margin-left: 42px;
  margin-top: 47px;
  /* margin-bottom: 16px; */
}

.verification-code-input {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.goback-btn {
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
  color: #fff;
  background: rgba(208, 79, 79, 1);
  width: 314px;
  height: 40px;
  border-radius: 10px;
  border: none;
  font-size: 16px;
}

.no-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.verification-code-box {
  width: 40px;
  height: 40px;
  font-size: 16px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 5px;
  margin-left: 5px;
}

.security-main {
  display: flex;
  /* align-items: center; */
  gap: 35px;
}

.sec-left {
  background-color: #fff;
  padding: 9px 19px 23px 19px;
  border-radius: 20px;
  flex: 1;
}

.main-sec-section-left {
  margin-top: 15px;
  padding: 8px;
  padding-left: 20px;
  border-radius: 8px;
  background: var(--Base-0, #FFF);
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.10);
}

.change-pass {
  cursor: pointer;
  margin-top: 15px;
  padding: 8px;
  padding-left: 20px;
  border-radius: 8px;
  background: var(--Base-0, #FFF);
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.10);
}


.title {
  font-size: 22px;
  line-height: 30px;
  font-weight: 500;
  margin-bottom: 5px;
}

.your-details-title {
  color: #0E132F;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  /* 154.545% */
  letter-spacing: -0.22px;
}

.payment-container {
  flex: 0.7;
}

.sec-right {
  background-color: #fff;
  padding: 3px 17px 19px 17px;
  border-radius: 20px;
  width: 361px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 17px; */
}

.payment-img {
  width: 100%;
  height: 100%;
}

.plan-green {
  background: url('../../assests/images/payment-green.svg');
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 12px 12px 12px 40px;

  display: inline-flex;
  border-radius: 10px;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  width: 326px;
  height: 60px;
  display: flex;
  align-items: center;
  gap: 15px;
  height: 86px;
  width: 382px;


}

.payment-desc {
  color: #FFF;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 20px;
}

.payment-monthYear {
  color: #FFF;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 25px;
}

.rem-time {
  color: #FFF;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.rem-background {
  border-radius: 30px;
  background: #49B27A;
  padding: 2px 5px;
  margin-top: 24px;
  width: 131px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.plan-purple {
  background: url('../../assests/images/purple-payment.svg');
  /* background-size: cover; */
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 30px 39px 32px 45px;

  display: inline-flex;
  border-radius: 10px;
  /* width: 98% !important; */
  /* margin-bottom: 19px; */
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  flex-direction: column;
  width: 382px;
  height: 214px;
  gap: 16px;
  justify-content: center;
}

.plan-free-trial {
  background: url('../../assests/images/free-trial-plan.svg');
  /* background-size: cover; */
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 15px 20px 40px 20px;

  display: inline-flex;
  border-radius: 10px;
  /* width: 98% !important; */
  /* margin-bottom: 19px; */
  box-shadow: 0px 5px 30px 0px #0000001A;
  flex-direction: column;
  width: 320px;
  height: 182px;
  gap: 16px;
  justify-content: center;
  margin-top: 14px;
}

.plan-basic {
  background: url('../../assests/images/basic-plan.svg');
  /* background-size: cover; */
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 15px 20px 40px 20px;

  display: inline-flex;
  border-radius: 10px;
  /* width: 98% !important; */
  /* margin-bottom: 19px; */
  box-shadow: 0px 5px 30px 0px #0000001A;
  flex-direction: column;
  width: 320px;
  height: 182px;
  gap: 16px;
  justify-content: center;
  margin-top: 14px;
}

.plan-pro {
  background: url('../../assests/images/pro-plan.svg');
  /* background-size: cover; */
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 15px 20px 40px 20px;

  display: inline-flex;
  border-radius: 10px;
  /* width: 98% !important; */
  /* margin-bottom: 19px; */
  box-shadow: 0px 5px 30px 0px #0000001A;
  flex-direction: column;
  width: 320px;
  height: 182px;
  gap: 16px;
  justify-content: center;
  margin-top: 14px;
}

.plan-standard {
  background: url('../../assests/images/standard-plan.svg');
  /* background-size: cover; */
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 15px 20px 40px 20px;

  display: inline-flex;
  border-radius: 10px;
  /* width: 98% !important; */
  /* margin-bottom: 19px; */
  box-shadow: 0px 5px 30px 0px #0000001A;
  flex-direction: column;
  width: 320px;
  height: 182px;
  gap: 16px;
  justify-content: center;
  margin-top: 14px;
}

.plan-early-bird {
  background: url('../../assests/images/elary-bird-plan.svg');
  /* background-size: cover; */
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 15px 20px 40px 20px;

  display: inline-flex;
  border-radius: 10px;
  /* width: 98% !important; */
  /* margin-bottom: 19px; */
  box-shadow: 0px 5px 30px 0px #0000001A;
  flex-direction: column;
  width: 320px;
  height: 182px;
  gap: 16px;
  justify-content: center;
  margin-top: 14px;
}

.plan-no {
  background: url('../../assests/images/no-plan.svg');
  /* background-size: cover; */
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 15px 20px 40px 20px;

  display: inline-flex;
  border-radius: 10px;
  /* width: 98% !important; */
  /* margin-bottom: 19px; */
  box-shadow: 0px 5px 30px 0px #0000001A;
  flex-direction: column;
  width: 320px;
  height: 182px;
  gap: 16px;
  justify-content: center;
  margin-top: 14px;
}

.plan-free {
  background: url('../../assests/images/free-plan.svg');
  /* background-size: cover; */
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 15px 20px 40px 20px;

  display: inline-flex;
  border-radius: 10px;
  /* width: 98% !important; */
  /* margin-bottom: 19px; */
  box-shadow: 0px 5px 30px 0px #0000001A;
  flex-direction: column;
  width: 320px;
  height: 182px;
  gap: 16px;
  justify-content: center;
  margin-top: 14px;
}

.content-width {
  width: 30% !important;
  border-radius: 30px !important;
}

.delete-modal-width {
  width: 450px !important;
  border-radius: 20px !important;

}

.section-pd {
  padding: 10px 0px;
}

.detail-title-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 4px;
  width: 100%;
}

.save-changes-btn {
  width: 117px;
  height: 31px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #469B88;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
  color: #fff;
  /* background: rgba(70, 155, 136, 1); */
  border: none;
  margin-bottom: 5px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 166.667% */
  letter-spacing: -0.12px;
}

.pur-plan-para {
  color: #FFF;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 145.455% */
  margin-bottom: -3px;
}

.update-plan {
  width: 283px;
  height: 46px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #FEFEFE;
  text-align: center;
  /* padding: 6px; */
  color: #ffffff;
  font-weight: 700;
  cursor: pointer;
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;

}

.section-main {
  display: flex;
  gap: 35px;
}

.left-flex {
  flex: 1.5;
}

.opt-success-title {
  font-size: 30px !important;
  color: #0e132f;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.32px;
}

@media only screen and (max-width: 1699px) {
  .setting-tab li .nav-link {
    padding: 10px 45px !important;
  }
}

@media only screen and (max-width: 1399px) {
  .setting-tab li .nav-link {
    padding: 10px 30px !important;
  }
}

@media only screen and (max-width: 1200px) {
  .setting-tab li .nav-link {
    padding: 10px 30px !important;
  }
}

@media only screen and (max-width: 1199px) {
  .setting-tab li .nav-link {
    padding: 10px 50px !important;
  }

  .profile-img-setting img {
    width: 100%;
  }

  .camera {
    left: 60%;
    top: 15%;
  }

  .setting-wrp {
    width: 75%;
  }
}

@media only screen and (max-width: 979px) {
  .setting-tab li .nav-link {
    padding: 10px 45px !important;
  }

  .setting-page .tab-pane {
    flex-direction: column;
  }

  .profile-img-setting {
    display: flex;
    position: relative;
    justify-content: center;
  }

  .profile-img-setting img {
    width: 35%;
  }

  .camera img {
    width: 100%;
  }

  .camera {
    left: 55%;
    top: 15%;
  }
}

@media only screen and (max-width: 649px) {
  .setting-tab li .nav-link {
    padding: 10px 30px !important;
  }

  .setting-wrp .nav {
    flex-direction: column;
  }

  .setting-tab li .nav-link {
    padding: 10px 120px !important;
    width: 100%;
    margin-bottom: 15px;
  }

  .done-img {
    width: 250px;
  }
}

@media only screen and (max-width: 479px) {

  /* .profile-wrp .profile-img-setting img {
        width: 50%;
    } */
  .heading-2fa h3 {
    font-size: 25px;
    line-height: 30px;
  }

  .done-img {
    width: 290px;
  }

  .verify-btn {
    padding: 16px 57px !important;
  }
}

@media only screen and (max-width: 390px) {

  /* .profile-wrp .profile-img-setting img {
        width: 50%;
    } */
  .heading-2fa h3 {
    font-size: 23px;
    line-height: 30px;
  }

  .done-img {
    width: 264px;
  }

  .verify-btn {
    padding: 16px 42px !important;
  }
}

@media only screen and (max-width: 375px) {

  /* .profile-wrp .profile-img-setting img {
        width: 50%;
    } */
  .heading-2fa h3 {
    font-size: 20px;
    line-height: 30px;
  }

  .done-img {
    width: 251px;
  }

  .verify-btn {
    padding: 16px 35px !important;
  }
}