/* Modal Styles */
.modal-background-calendar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-background-calendar.open-modal {
  display: flex;
  animation: fadeIn 0.3s ease;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modal-content-calendar {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  position: relative;
  width: 40%;
}

.close-modal-button-calendar {
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
  outline: none;
}

/* Button Styles */
.open-modal-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.open-modal-button:hover {
  background-color: #0056b3;
}

.date-picker-style input[type='text'] {
  height: 48px;
  font-size: 18px !important;
  border-radius: 10px !important;
  background-color: #fdfdfd !important;
  border: 1px solid #ced4da;
  width: 100%;
  display: block;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 0px 10px;
  font-weight: 400;
  line-height: 1.5;
}
.date-picker-style input[type='text']::placeholder {
  color: #212529;
}
.react-datepicker-wrapper.date-picker-style.full-width {
  width: 100%;
}

.date-picker-div {
  position: relative;
}

.date-picker-div i {
  position: absolute;
  right: 5%;
  top: 15px;
  cursor: pointer;
}

.popup-text {
  font-size: 13px !important;
}

/*---------------------------------------Add event Modal Form ------------------------------ */

.calendar-container {
  position: relative;
}
.calendar-container .fc-button-group button {
  background-color: #469b88 !important;
}
.calendar-container .fc .fc-scrollgrid-liquid {
  border: none;
}

.calendar-container .fc-theme-standard th {
  border: none;
  background-color: white;
  border-radius: 15px;
  vertical-align: inherit;
}
.calendar-container .fc-theme-standard td {
  border-radius: 15px;
  border: none;
}
.calendar-container .fc .fc-daygrid-day-number {
  color: #474747;
  font-size: 20px;
  border-radius: 100%;
  font-weight: bold;
  padding: 3px 12px;
  background-color: #ececec;
}

.calendar-container .fc-col-header-cell .fc-day {
  border: none;
}

.calendar-container .fc .fc-scrollgrid-section-header {
  border-radius: 10px;
}

.calendar-container .fc-col-header tr {
  /* background-color: #fff; */
}
.calendar-container .fc .fc-col-header-cell-cushion {
  color: #474747;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.2px;
  vertical-align: middle;
}

.calendar-container .fc-scrollgrid-sync-inner:active {
  background-color: #001b49 !important;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.calendar-container .fc-event-title-container {
  background-color: #469b88;
  color: white;
  border-radius: 5px;
}
.calendar-container .fc-event-title {
  font-weight: 700 !important;
}
.calendar-container .fc-h-event {
  border: none;
}
.calendar-container .fc-event-title fc-sticky {
  font-weight: 500;
}

.event-form {
  /* display: inline-flex; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Style for the form container */
.calander-popup-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.submit-btn {
  width: 100%;
}

#event-details {
  resize: vertical; /* Allow vertical resizing of the textarea */
}

.calendar-container i {
  margin-right: 12px;
}
.event-description {
  resize: none;
  padding-top: 10px;
  height: 100%;
}

.calendar-container .input-border::placeholder {
  color: #565a6d !important;
}
.calendar-modal-add {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.calendar-modal-add .comn-btn {
  font-size: 18px;
}

/*---------------------------------------Add event Modal Form  end------------------------------ */

/* -----------------------Calendar day css-------------------------- */

.calendar-container .fc .fc-daygrid-body-balanced .fc-daygrid-day-events {
  width: 75px;
  bottom: 20px;
  left: 20px;
  background-color: #469b88;
  border-radius: 10%;
  border-spacing: 0;
}

.fc .fc-daygrid-more-link {
  color: #fff;
  padding: 10px 10px;
  font-size: 14px;
  text-align: center;
}

.fc .fc-daygrid-more-link:hover{
  color: #ffffff !important;
  padding: 10px 10px;

}

.calendar-container .fc-scrollgrid-sync-table {
  border-spacing: 8px;
  padding-top: 20px;
}

.calendar-container .fc .fc-daygrid-day-frame {
  background-color: #fff;
  border-radius: 15px;
}
.calendar-container .fc .fc-daygrid-day-top {
  padding-right: 15px;
}

.calendar-container .fc-scrollgrid-sync-inner {
  border-color: #fff;
  padding: 0px 0px;
}

.active-day-header-calendar {
  background-color: #001b49 !important;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
  width: 210px;
  height: 50px;
}

.active-day-header-calendar::before {
  content: '';
  position: absolute;
  background-color: #47c1fe;
  height: 55px;
  width: 55px;
  top: -25px;
  left: -21px;
  border-radius: 50%;
}
.active-day-header-calendar::after {
  content: '';
  position: absolute;
  background-color: #dd7802;
  height: 55px;
  width: 55px;
  bottom: -28px;
  right: -21px;
  border-radius: 50%;
}
.cell-background-today-bg {
  background-color: #001b49 !important;
}

.calendar-container .fc .fc-toolbar {
  display: inline;
}

.calendar-container .fc .fc-toolbar-title {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  line-height: 34px;
  letter-spacing: -0.24px;
  margin-bottom: 30px;
}

/* -----------------------Calendar day css end-------------------------- */

/* --------------------------Calendar header css ----------------------------- */

.calendar-container .fc .fc-today-button,
.calendar-container .fc-button-group {
  display: none !important;
}

.custom-button-group {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  cursor: pointer;
}

.custom-button-group .left-btn-icon-calendar,
.right-btn-icon-calendar {
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}

.calendar-container .fc .fc-popover{
  z-index: 1;
}


.calendar-container .fc-event-time{
  display: none;
}

.calendar-container .fc-event-title{
  color: #fff;
  font-size: 14px;
  background-color: #469b88;
   padding: 5px 10px;
   border-radius: 3px;
}

.calendar-container .fc-daygrid-event-dot{
  display: none;
}

.custom-btn-grp-container-main{
  position: relative;
  margin: auto;
  width: 27%;
  top: 56px;

}

.projected-acct-balances{
  width: 254px;
  display: contents;

}


/* --------------------------Calendar header css end ----------------------------- */





/* -----------------------------------Media Query------------------------------------------------ */



@media only screen and (max-width: 1550px) and (min-width: 1200px) {


.projected-acct-balances .merchant-box{
  /* width: 20%; */
}

.cashflow-calendar-wrp .edit-btn{
  width: 90% !important;
}

.calendar-sec-first-wrp{
  width: 100% !important;
}



}
