.popover-container {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 10px;
  z-index: 1000;
  width: 200px;
}

.popover-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  border: none;
  background-color: white;
  padding: 0%;
  margin-bottom: 10px;
}

.year-change {
  cursor: pointer;
  font-size: 18px;
  opacity: 1.0;
}

.current-year {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Neurialgrotesk';
}

.months-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
}

.month-item {
  text-align: center;
  padding: 6px 0;
  cursor: pointer;
  border-radius: 5px;
  color: #767676;
  font-size: "16px";
  font-weight: 400;
  font-family: 'Neurialgrotesk';
}

.month-item:hover,
.month-item.selected {
  background: #469B88;
  color: white;
  font-weight: 400;
  font-family: 'Neurialgrotesk';
}

.month-item.disabled-month {
  color: #E0E0E0;
  cursor: not-allowed;
  opacity: 0.6;
}
.year-change.disabled {
  opacity: 0.3;
}